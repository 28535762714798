<template>
  <v-stepper v-model="current">
    <v-stepper-header>

      <v-sheet v-for="(step, index) in steps" :key="index">

        <v-stepper-step
              @click="switchStep(index)"
              :complete="current > index"
              :step="++index"
          >
            {{step.label}}
          </v-stepper-step>

        <v-divider></v-divider>
      </v-sheet>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <base-requirements @base-requirements-changed="setBaseRequirements" />
        <v-btn
            color="primary"
            @click="current = 2"
        >
          {{$t('next')}}
        </v-btn>

        <v-btn text @click="onCancelClicked">
          {{$t('cancel')}}

        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <qms-features-filter @qms-features-changed="setSelectedFeatures" />

        <v-btn
            color="primary"
            @click="current = 3"
        >
          {{$t('next')}}
        </v-btn>

        <v-btn text @click="onCancelClicked">
          {{$t('cancel')}}
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <qms-inquiry-summary
            :base-requirements="baseRequirements"
            :qms-features="selectedFeatures"
        />
        <v-btn text @click="onCancelClicked">
          {{$t('cancel')}}
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>

import BaseRequirements from "@/components/products/qms/stepper/steps/BaseRequirements"
import QmsFeaturesFilter from "@/components/products/qms/stepper/steps/QmsFeaturesFilter"
import QmsInquirySummary from "@/components/products/qms/stepper/steps/QmsInquirySummary";

export default {

  components: {QmsInquirySummary, BaseRequirements, QmsFeaturesFilter},
  data() {
    return {
      current: 1,
      baseRequirements: [],
      selectedFeatures: [],
      steps: [
        {label: this.$t('about_institution_label')},
        {label: this.$t('software_functionality')},
        {label: this.$t('software_request_summary')}
      ]
    }
  },

  methods: {

    setBaseRequirements(data) {
      this.baseRequirements = data
    },

    setSelectedFeatures(data) {
      this.selectedFeatures = data
    },

    switchStep(index) {
      this.current = index
    },

    onCancelClicked() {
      this.$emit('cancel-clicked')
    }
  },
}

</script>

<style>
.v-stepper__step:hover {
  cursor: pointer;
}
</style>
