<template>
  <v-sheet>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="fileName"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"

        ref="html2Pdf"
    >
      <section slot="pdf-content"  style="font-family: Arial,serif; margin: 5px;">
        <slot />
      </section>
    </vue-html2pdf>

    <v-btn v-if="showButton" small block outlined @click="generateReport" class="mb-4">{{$t('pdf_download_label')}}</v-btn>
  </v-sheet>

</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {VueHtml2pdf},
  props: {
    fileName: {
      type: String,
      default: 'pdf_output'
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  },

}

</script>
