<template>
  <v-sheet min-height="300" class="row">
    <div class="col-6">
      <h3 class="text-left">{{$t('software_capabilities')}}</h3>
      <draggable ref="standard" @start="onStart" @end="onStop" class="list-group" :list="qmsExtraFeatures" group="people" @change="log">
        <v-sheet
            class="list-group-item text-left ml-0"
            :class="marginBetweenItems"
            v-for="(element) in qmsExtraFeatures"
            :key="element.id"
        >
          <v-hover v-slot="{ hover }">
            <v-chip class="feature-button" :class="hover ? 'elevation-5' : ''" color="primary" outlined label>
              {{ element.name }}
              <v-icon small v-if="hover" right>
                {{dragIcon}}
              </v-icon>
            </v-chip>
          </v-hover>
        </v-sheet>
      </draggable>
    </div>

    <div class="col-6 pr-4">
      <h3 class="text-left">{{$t('default_and_optional_capabilities')}}</h3>

      <v-sheet v-if="selectedExtraFeatures.length === 0 && !dragStart">
        <div class="draggable-area text-caption pt-4 mt-4">
          {{$t('qms_selected_features')}}
          <div class="mt-2">
            <v-icon large>
              {{ dragIcon }}
            </v-icon>
          </div>
        </div>
      </v-sheet>

      <draggable ref="extra" @start="onStart" @end="onStop" class="list-group-features mt-4" :class="{'draggable-extra' : dragStart}" :list="selectedExtraFeatures" group="people" @change="log">
        <v-sheet
            class="list-group-item text-left ml-0"
            :class="marginBetweenItems"
            v-for="(element) in selectedExtraFeatures"
            :key="element.id"
        >
          <v-chip class="feature-button" label>{{ element.name }}</v-chip>

        </v-sheet>
      </draggable>
      <v-sheet
          class="list-group-item text-left ml-0"
          :class="marginBetweenItems"
          v-for="(element, index) in qmsStandardFeatures"
          :key="index"
      >
        <v-chip class="feature-button" label disabled color="primary" text-color="#fff">{{ element.name }}</v-chip>
      </v-sheet>
    </div>

    <v-sheet v-if="showPdfGenerator">
      <v-btn block outlined @click="generatePdf">{{$t('pdf_download_label')}}</v-btn>

      <v-sheet class="ma-3" style="width: 100%">
        <PdfGenerator file-name="qms_selected_features" ref="pdfGenerator">
          <h3 style="margin: 15px 0">
            {{$t('qms_extra_features_label')}}
          </h3>
          <v-sheet v-if="selectedExtraFeatures.length === 0">
            {{$t('qms_no_extra_feature_selected')}}
          </v-sheet>
          <v-sheet
              class="list-group-item text-left ml-0"
              :class="marginBetweenItems"
              v-for="(element, index) in selectedExtraFeatures"
              :key="element.id"
          >
            <v-chip class="feature-button" style="margin-bottom: 10px" label> {{ ++index }}. {{ element.name }}</v-chip>
          </v-sheet>

          <h3 style="margin:15px 0">
            {{$t('qms_standard_features_label')}}
          </h3>
          <v-sheet
              class="list-group-item text-left ml-0"
              :class="marginBetweenItems"
              v-for="(element, index) in qmsStandardFeatures"
              :key="index"
          >
            <v-chip class="feature-button" style="margin-bottom: 10px" label disabled color="primary" text-color="#333">{{++index}}. {{ element.name }}</v-chip>
          </v-sheet>
        </PdfGenerator>

      </v-sheet>
    </v-sheet>

  </v-sheet>
</template>
<script>
import draggable from "vuedraggable";
import PdfGenerator from "@/modules/PdfGenerator";
export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    PdfGenerator,
    draggable
  },

  computed: {

    //TODO: missing translations or restful request
    qmsExtraFeatures() {
      return [
        { name: "Możliwość prezentacji treści multimedialnych", id: 1001 },
        { name: "Integracja z systemami medycznymi mMedica", id: 1002 },
        { name: "Integracja z systemem Siegla MMS", id: 1011 },
        { name: "Integracja z systemami medycznymi KAMSOFT", id: 1003 },
        { name: "Przywoływacze stanowiskowe jako aplikacja desktopowa", id: 1004 },
        { name: "Publiczny podgląd stanu kolejki", id: 1005 },
        { name: "Aplikacja mobilna dla Twojego Interesanta", id: 1006 },
        { name: "Integracja z systemami kontroli dostępu", id: 1007 },
        { name: "Wydruk biletów przy stanowiskach rejestracji", id: 1101},
        { name: "Potwierdzanie wizyty w urzędzie kodem QR", id: 1008 },
        { name: "Powiadomienia SMS", id: 1010 },
      ]

    },
    qmsStandardFeatures() {
      return [
        { name: "Licencja na nieograniczoną ilość stanowisk obsługi", id: 1 },
        { name: "Obsługa dowolnej ilości automatów biletowych", id: 2 },
        { name: "Przywoływacze stanowiskowe w formie urządzeń mobilnych", id: 3 },
        { name: "Powiadomienia email", id: 4 },
        { name: "Tworzenie scenariuszy obsługi", id: 5 },
      ]
    }
  },

  data() {
    return {
      selectedExtraFeatures: [],
      marginBetweenItems: 'my-4',
      dragIcon: 'mdi-drag-variant',
      dragStart: false,
      showPdfGenerator: false
    }
  },

  watch: {
    selectedExtraFeatures: {
      deep: true,
      handler() {
        this.$emit('qms-features-changed', {
          standard: {
            label: this.$t('qms_standard_features_label'),
            data: this.qmsStandardFeatures
          },
          extra: {
            label: this.$t('qms_extra_features_label'),
            data: this.selectedExtraFeatures
          }
        })
      }
    }
  },

  methods: {

    generatePdf: function () {
      this.$refs.pdfGenerator.generateReport();
    },

    onStart: function () {
      this.dragStart = true
    },

    onStop: function () {
      this.dragStart = false
    },

    log: function() {
      // console.log(evt);
    }
  }
};
</script>

<style>

.draggable-area {

  min-height: 100px;
  outline: 3px dashed #cdcdcd;
}

.draggable-extra {
  min-height: 100px;
  outline: 3px dashed #333;
}

.feature-button {
  width: 100%;
}

</style>
