<template>
  <v-sheet>
    <v-textarea
        outlined
        name="input-7-1"
        :placeholder="this.$t('qms_inquiry_additional_message')"
        v-model="additionalMessage"
        :hint="this.$t('additional_info_label')"
    ></v-textarea>

    <v-radio-group v-model="proceedMethod">
<!--      <v-radio value=1 label="Wyślij zapytanie i pobierz podsumowanie w wersji pliku PDF"></v-radio>
      <v-radio value=2 label="Wyślij zapytanie bez pobierania pliku PDF"></v-radio>
      <v-radio value=3 label="Pobierz podsumowanie PDF bez przesyłania zapytania."></v-radio>-->

      <v-radio v-for="available in availableProceedMethods"
               :key="available.id"
               :value="available.id"
               :label="$t(available.label)"
      >

      </v-radio>

    </v-radio-group>

    <PdfGenerator file-name="qms_summary" ref="pdfGenerator">
      <div class="pdf-content">

        <h1 style="text-align: center; margin: 20px 0;">Siegla QMS - zapytanie ofertowe</h1>
        <p style="text-align: center; margin-bottom: 10px;">{{ new Date().toLocaleDateString() }}</p>
        <v-container>
          <h3>{{ $t('about_institution_label') }}</h3>
          <v-row v-for="(requirement) in baseRequirements" :key="requirement.label">
            <v-col>{{ requirement.label }}</v-col>
            <v-col>{{ requirement.data }}</v-col>
          </v-row>
        </v-container>


        <v-container v-if="qmsFeatures.length !== 0">
          <h3 class="text-heading">{{ qmsFeatures.extra.label }}</h3>
          <v-row v-for="(feature) in qmsFeatures.extra.data" :key="feature.id">
            <v-col>
              <v-chip>{{ feature.name }}</v-chip>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="qmsFeatures.length !== 0">
          <h3 class="text-heading">{{ qmsFeatures.standard.label }}</h3>
          <v-row v-for="(feature) in qmsFeatures.standard.data" :key="feature.id">
            <v-col><v-chip>{{ feature.name }}</v-chip></v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col>
              <div>
                <h3>{{ $t('additional_info_label') }}</h3>
                {{ additionalMessage }}
              </div>
            </v-col>
          </v-row>
        </v-container>

      </div>
    </PdfGenerator>
    <v-btn class="mb-6" block color="primary" @click="generatePdf">{{ calculateSubmitLabel }}</v-btn>
  </v-sheet>

</template>

<script>
import PdfGenerator from "@/modules/PdfGenerator";
import axios from "axios";
import config from "../../../../../config"

export default {
  props: ['qmsFeatures', 'baseRequirements'],
  data() {
    return {
      additionalMessage: null,
      availableProceedMethods: [
        {id: 1, label: "get_pdf_and_submit"},
        {id: 2, label: "get_pdf"},
        {id: 3, label: "only_submit"},
      ],
      proceedMethod: 1
    }
  },

  computed: {

    calculateSubmitLabel() {
      for (let i =0; i<this.availableProceedMethods.length; i++) {
        if(this.proceedMethod === this.availableProceedMethods[i].id)
          return this.$t(this.availableProceedMethods[i].label)
      }

      return this.availableProceedMethods[0].label
    }
  },

  components: {PdfGenerator},
  methods: {

    submitQuestion() {
      
      let postData = {
        template: 'qms_inquiry',
        title: "Siegla QMS - Zapytanie ofertowe",
        base: this.baseRequirements,
        features: this.qmsFeatures,
        message: this.additionalMessage
      }
      axios.post(config.MAIL_API_URL, postData)
          .then();
    },

    getPdf() {

      this.$refs.pdfGenerator.generateReport();
    },

    generatePdf: function () {

      switch (this.proceedMethod) {
        case 1:
          this.getPdf();
          this.submitQuestion();
          break;
        case 2:
          this.getPdf();
          break;
        case 3:
          this.submitQuestion();
          break;
      }

    }

  },
}
</script>

<style>
.pdf-content > div {

  margin-bottom: 10px;
}

.text-heading {

  margin-bottom: 15px;
}

</style>
