<template>
<v-col>
  <slot>
    <v-btn
        v-html="label"
        outlined
        :class="className"
        block
        v-bind="$attrs"
    >
    </v-btn>
  </slot>
</v-col>
</template>

<script>
export default {

  props: {
    label: {
      type: String
    },
    hint: {
      type: String
    },
    className: {
      type: String
    }
  },

}

</script>
