<template>

  <product-page :title="title" :subtitle="subtitle">
    <v-sheet class="py-6">
      <v-container>
        <v-row justify="center">
          <v-col sm="6">
            <ItemGroupWrapper>
              <Button to="qms/features" large :label="this.$t('qms_features_overview')" />
              <v-dialog
                  transition="dialog-top-transition"
                  max-width="900"
                  v-model="qmsConfiguratorOverlay"
                  v-if="qmsConfiguratorOverlay"
              >
                <template>
                  <v-card>
                    <v-card-title>
                      {{$t('qms_configurator_label')}}
                    </v-card-title>
                    <qms-stepper @cancel-clicked="qmsConfiguratorOverlay = false" />
                  </v-card>
                </template>
              </v-dialog>

              <Button large :label="this.$t('qms_configurator_label')" @click.native="qmsConfiguratorOverlay = true" />
            </ItemGroupWrapper>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <qms-components />
    </v-sheet>
  </product-page>

</template>

<script>
import QmsComponents from "@/components/products/qms/QmsComponents";
import Button from "@/modules/Button";
import ItemGroupWrapper from "@/modules/ItemGroupWrapper";
import QmsStepper from "@/components/products/qms/stepper/QmsStepper";
import ProductPage from "@/components/products/ProductPage";
export default {
  components: {ProductPage, QmsStepper, ItemGroupWrapper, Button, QmsComponents},
  data() {
    return {
      showOverview: false,
      qmsConfiguratorOverlay: false,
      //TODO: missing translations or restful request
      title: 'Siegla QMS',
      subtitle: 'System zarządzania kolejkami to produkt, który zainicjował nowy etap obsługi Klienta. Pozwala on na sprawne zarządzanie ruchem klientów, ograniczenie czasu oczekiwania interesantów oraz częściowe wyeliminowanie kolejek. System zapewnia komfort dla osób oczekujących, ma również bezpośredni wpływ na zadowolenie i opinię o danej placówce.',
    }
  },
}
</script>
