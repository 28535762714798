<template>
  <v-sheet color="#fbfbfb">
    <v-divider/>
    <v-sheet class="ma-auto" color="#fff">
      <v-container>
        <v-row>
          <v-col class="my-8">
            <TextHeading :level=2>{{title}}</TextHeading>
            <div class="grey--text mt-2">{{subtitle}}</div>
          </v-col>
        </v-row>
      </v-container>
      <multi-features-box :features="qmsComponents" />
    </v-sheet>
  </v-sheet>
</template>

<script>
import TextHeading from "@/modules/TextHeading";
import MultiFeaturesBox from "@/components/products/common/MultiFeaturesBox";
export default {
  components: {MultiFeaturesBox, TextHeading},
  data() {

    //TODO: missing translations or restful request
    return {

      title: 'Komponenty systemu Siegla QMS',
      subtitle: '',

      qmsComponents: [
        {
          name: "Automat biletowy",
          short_description: "",
          description: "Kiosk wyposażony w intuicyjny interfejs graficzny, za pomocą którego klient wybiera szczegółówy rodzaj usługi (dział, numer okienka czy pokoju). Po dokonaniu wyboru – kiosk drukuje bilet z numerkiem, miejscem docelowym, aktualną godziną oraz opcjonalnym czasem oczekiwania. Kiosk jest urządzeniem z ekranem dotykowym i wbudowaną drukarką."
        },
        {
          name: "Oprogramowanie zarządzające",
          short_description: "",
          description: "Oprogramowanie udostępniające interfejs WWW, będące głównym elementem sterującym systemu. Aplikacja gromadzi niezbędne dane, podejmuje decyzje o wyborze kolejnego petenta, a także pozwala na konfigurację oraz przegląd działania systemu SieglaQMS. Pełna lista funkcjonalności dostępna jest <a href='/products/qms/features'>TUTAJ</a>."
        },
        {
          name: "Przywoływacz stanowiskowy",
          short_description: "",
          description: "Urządzenie mobilne wyposażone w dedykowaną aplikację dla pracowników realizujących obsługę interesantów. Oprogramowanie oferuje szereg unikalnych cech usprawniających obsługę, takich jak: Podgląd osób oczekujących z wyszczególnieniem zarezerwowanych wizyt, transferowanie i wstrzymywanie spraw oraz ponawianie odczytu wezwania interesanta."
        },
        {
          name: "Ekran informacyjny",
          short_description: "",
          description: "Komponent systemu orpowiedzialny za emisję i graficzne wyróżnienie wzywanego numeru. Zazwyczaj, taki komponent jest sprzężony z systemem powiadomień głosowych. Po emisji zapowiedzi, system może prezentować kolejkę oczekujących interesantów oraz dowolne treści multimedialne."
        },
        {
          name: "Ekran stanowiskowy",
          short_description: "",
          description: "Ekran stanowiskowy może przybrać formę wysokokontrastowej matrycy LED lub ekranu LCD. Układ taki jest zazwyczaj instalowany bezpośrednio przy stanowisku, a jego główną cechą jest dodatkowe wskazanie interesantowi stanowiska, przy którym ma się stawić."
        },
        {
          name: "Urządzenia sieciowe",
          short_description: "",
          description: "Zależnie od docelowego miejsca instalacji systemu, poszczególne komponenty systemu mogą komunikować się zarówno przewodoo jak i bezprzewodowo, a także uwzględniając bieżącą konfigurację sieciową klienta."
        },
      ]
    }
  },
}
</script>

<style scoped>

.item-hovered {
  cursor: pointer !important;
}

</style>
