<template>
<v-form>
  <TextHeading :level=5 class-name="text-left">{{$t('base_data')}}</TextHeading>

  <v-text-field
      v-model="clientName"
      clearable
      :counter="200"
      :label="$t('client_name_label')"
      required
  ></v-text-field>

  <v-text-field
      v-model="clientPhone"
      :counter="15"
      clearable
      :label="$t('phone_label')"
      required
  ></v-text-field>

  <v-container fluid>
    <v-row align="center">

  <v-select
      v-model="selectedInstType"
      :items="institutionType"
      item-text="name"
      item-value="id"
  />

    </v-row>
  </v-container>

  <v-text-field
      clearable
      v-model="clientEmail"
      :counter="200"
      :label="$t('mail_address')"
      required
  ></v-text-field>


  <date-input :label="$t('qms_installation_date')" @dateChanged="dateChanged" />

  <TextHeading :level=5 class-name="text-left">{{$t('qms_requirements_label')}}</TextHeading>
  <v-text-field
      v-model="serviceDeskAmount"
      :counter="10"
      type="number"
      :label="$t('service_desk_amount')"
      required
  ></v-text-field>
  <v-text-field
      v-model="tdmAmount"
      type="number"
      :label="$t('tdm_amount')"
      required
  ></v-text-field>
  <v-text-field
      v-model="displayAmount"
      type="number"
      :label="$t('display_amount')"
      required
  ></v-text-field>
</v-form>
</template>

<script>
import TextHeading from "@/modules/TextHeading";
import DateInput from "@/modules/DateInput";
export default {
  components: {DateInput, TextHeading},

  computed: {
    institutionType() {
      let data =  [
        {id: 1, name: "qms_request_client_profile_med"},
        {id: 2, name: "qms_request_client_profile_service"},
        {id: 3, name: "qms_request_client_profile_other"},
      ];

      for(let i=0; i<data.length; i++) {
        data[i].name = this.$t(data[i].name)
      }
      return data
    },

    baseRequirementsData() {
      let institutionLabel = this.getInstitutionLabelById(this.selectedInstType)
      return [
        {label: this.$t('client_name_label'), data: this.clientName},
        {label: this.$t('mail_address'), data: this.clientEmail},
        {label: this.$t('phone_label'), data: this.clientPhone},
        {label: this.$t('qms_institution_type'), data: institutionLabel},
        {label: this.$t('qms_installation_date'), data: this.installationDate},
        {label: this.$t('service_desk_amount'), data: this.serviceDeskAmount},
        {label: this.$t('tdm_amount'), data: this.tdmAmount},
        {label: this.$t('display_amount'), data: this.displayAmount},
      ]
    }
  },

  watch: {
    baseRequirementsData: {
      deep: true,
      handler() {
        this.$emit('base-requirements-changed', this.baseRequirementsData)
      }
    }
  },

  data() {
    return {
      clientName: '',
      clientPhone: '',
      clientEmail: '',
      installationDate: '',
      serviceDeskAmount: '',
      selectedInstType: 1,
      tdmAmount: '',
      displayAmount: ''
    }
  },
  methods: {
    dateChanged(value) {
      this.installationDate = value
    },

    getInstitutionLabelById(id) {

      for(let i=0; i<this.institutionType.length; i++) {
        if(this.institutionType[i].id === id)
          return this.institutionType[i].name
      }
      return id
    },
  },
}

</script>

<style>

.v-list-item__title {
  text-align: left;
}

</style>
