<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="date"
          :label="label"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
        locale="pl"
        :min="offsetDate"
        color="primary"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {

  props: {
    label: {
      type: String,
      default: ''
    },
  },

  computed: {
    offsetDate() {
      let date = new Date();
      let numberOfDaysToAdd = 7;
      date.setDate(date.getDate() + numberOfDaysToAdd);

      return date.toISOString().substr(0, 10)
    }
  },


  watch: {
    date(value) {
      this.$emit('dateChanged', value)
    }
  },

  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    date: '',
    menu: false
  }),
}
</script>
